<template>
  <div>
    <v-row justify="start">
      <v-btn
        class="ml-3 capitalize"
        color="primary"
        depressed
        @click="openDialog"
        rounded
      >
        <v-icon
          class="pa-1 white primary--text rounded-circle ml-n3 mr-1"
          size="17"
        >
          mdi-plus
        </v-icon>
        Add Payment
      </v-btn>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :headers="headers" :items="payments" hide-default-footer>
          <template v-slot:item.numbers="{ index }">
            <div>{{ index + 1 }}</div>
          </template>
          <template v-slot:item.upgrade.amount="{ item }">
            <div>
              <small>$</small>{{ item["upgrade"]["amount"].toLocaleString() }}
            </div>
          </template>
          <template v-slot:item.created_at="{ item }">
            <div>{{ new Date(item.created_at).toLocaleDateString() }}</div>
          </template>
          <template v-slot:item.lifetime="{ item }">
            <div class="text-capitalize">{{ item.lifetime }}</div>
          </template>
          <template v-slot:item.action="{ item }">
            <div>
              <v-btn @click="deletePayment(item)" icon>
                <v-icon color="error">mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
        <div v-if="pagination.total > 1">
          <v-pagination
            v-model="pagination.page"
            :total-visible="7"
            :length="pagination.total"
            @input="nextPage"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-dialog max-width="400" v-model="dialog">
      <v-card flat>
        <v-form lazy-validation ref="form" class="text-left">
          <v-toolbar class="primary white--text font-weight-bold" flat>
            <div>Add New Payment</div>
            <v-spacer></v-spacer>
            <v-icon @click="closeDialog" color="white" size="20">
              mdi-close
            </v-icon>
          </v-toolbar>
          <div class="pa-5">
            <label>User Email <span class="error--text">*</span></label>
            <v-text-field
              v-model="form.email"
              outlined
              dense
              background-color="secondary"
              rounded
              placeholder="Enter User email address"
              :rules="[(v) => !!v || 'This field is required']"
            ></v-text-field>
            <label>Upgrade Name <span class="error--text">*</span></label>
            <v-select
              v-model="form.upgrade_id"
              outlined
              rounded
              background-color="secondary"
              :items="upgrades"
              dense
              :loading="loadUpgrades"
              placeholder="Enter category name"
              :rules="[(v) => !!v || 'This field is required']"
            ></v-select>
            <v-text-field
              v-model="form.transaction_id"
              outlined
              dense
              background-color="secondary"
              rounded
              placeholder="Enter the transaction ID from payment processor"
              :rules="[(v) => !!v || 'This field is required']"
            ></v-text-field>
            <v-text-field
              v-model="form.flw_ref"
              outlined
              dense
              background-color="secondary"
              rounded
              placeholder="Enter the payment reference from payment processor"
              :rules="[(v) => !!v || 'This field is required']"
            ></v-text-field>
            <v-btn
              block
              rounded
              depressed
              :loading="loadBtn"
              class="capitalize primary"
              @click="$refs.form.validate() ? addPayment() : null"
            >
              Submit
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar bottom right>
      {{ error_text }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  name: "PaymentsPage",
  data() {
    return {
      pagination: {
        page: 1,
        total: 1,
      },
      search: "",
      loadBtn: false,
      form: {},
      dialog: false,
      headers: [
        { text: "#", value: "numbers", sortable: false, oderable: false },
        { text: "User Name", value: "user.name" },
        { text: "User Email", value: "user.email" },
        { text: "Plan", value: "upgrade.name" },
        { text: "Amount", value: "upgrade.amount" },
        { text: "Reference", value: "reference" },
        { text: "Transaction ID", value: "invoice_number" },
        { text: "Lifetime Access", value: "lifetime" },
        { text: "Date", value: "created_at" },
        { text: "Action", value: "action" },
      ],
      payments: [],
      error_text: "",
      loadUpgrades: false,
      upgrades: [],
    };
  },
  created() {
    this.getPayments();
    this.getUpgrades();
    this.$root.$on("search", (data) => {
      this.search = data;
      let query = "?page=1&search=" + this.search;
      this.getPayments(query);
    });
  },
  methods: {
    ...mapActions("payments", [
      "get_payments",
      "add_payment",
      "delete_payment",
    ]),
    ...mapMutations("auth", ["LOADING"]),
    async getUpgrades() {
      this.loadUpgrades = true;
      try {
        const response = await this.$axios.get("/upgrades");
        response.data.map((item) => {
          let upgrade = {};
          upgrade.value = item.id;
          upgrade.text = item.name;
          this.upgrades.push(upgrade);
        });
        this.loadUpgrades = false;
      } catch (e) {
        this.loadUpgrades = false;
        this.error_text = e.response.data.message;
      }
    },
    async getPayments(query) {
      if (!query) {
        query = "?page=" + this.pagination.page;
      }
      this.LOADING(true);
      try {
        const response = await this.get_payments(query);
        this.payments = response.data.data;
        this.pagination.page = response.data.current_page;
        this.pagination.total = response.data.last_page;
        this.LOADING(false);
      } catch (e) {
        this.LOADING(false);
        this.error_text = e.response.data.message;
      }
    },
    nextPage() {
      let search = this.search;
      let page = this.pagination.page;
      let query = "?page=" + page;
      if (search !== "") {
        query = "?page=" + page + "&search=" + search;
      }
      this.getPayments(query);
    },
    async addPayment() {
      this.loadBtn = true;
      this.form.admin = "admin";
      try {
        const response = await this.add_payment(this.form);
        this.getPayments();
        this.loadBtn = false;
        this.closeDialog();
        this.$response.sendSuccess(response);
      } catch (e) {
        this.loadBtn = false;
        this.$response.sendError(e);
      }
    },
    async deletePayment(payment) {
      let confirmDelete = confirm("This action is not reversible...");
      if (confirmDelete) {
        this.LOADING(true);
        try {
          const response = await this.delete_payment(payment);
          this.getPayments();
          this.LOADING(false);
          this.$response.sendSuccess(response);
        } catch (e) {
          this.LOADING(false);
          this.$response.sendError(e);
        }
      }
    },
    openDialog(item) {
      this.form = Object.assign({}, item);
      this.dialog = true;
    },
    closeDialog() {
      this.form = {};
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
